<template>
  <grid
    item-min-width="300px"
    class="align-center account"
  >
    <column-picker
      :selected-col-id.sync="computedNumCol"
      :consolidated.sync="computedNumConsolidated"
      :filters="filters"
      :label="$t(`t.Numerator`)"
      :attach="false"
      :show-icon="true"
      sum-cols-only
      currency-disabled
    />
    <column-picker
      :selected-col-id.sync="computedDenCol"
      :consolidated.sync="computedDenConsolidated"
      :filters="filters"
      :label="$t(`t.Denominator`)"
      :attach="false"
      :show-icon="true"
      sum-cols-only
      currency-disabled
    />
  </grid>
</template>

<script>
export default {
  components: {
    ColumnPicker: () => import('@/components/column-picker'),
    Grid: () => import('@/components/grid')

  },
  computed: {
    computedNumCol: {
      get () { return this.dataNumCol },
      set (v) {
        this.dataNumCol = v
        this.dataDocumentEmit()
      }
    },
    computedNumConsolidated: {
      get () { return this.dataNumConsolidated },
      set (v) {
        this.dataNumConsolidated = v
        this.dataDocumentEmit()
      }
    },
    computedDenCol: {
      get () { return this.dataDenCol },
      set (v) {
        this.dataDenCol = v
        this.dataDocumentEmit()
      }
    },
    computedDenConsolidated: {
      get () { return this.dataDenConsolidated },
      set (v) {
        this.dataDenConsolidated = v
        this.dataDocumentEmit()
      }
    },
    filters () {
      return { 'document-types': [[`${this.targetDocTy}`], []] }
    }
  },
  methods: {
    emitDocument () {
      const doc = {
        den: {
          col: this.dataDenCol
        },
        num: {
          col: this.dataNumCol
        },
        type: 'percent'
      }

      if (this.dataDenConsolidated != null) {
        doc.den.consolidated = this.dataDenConsolidated
      }

      if (this.dataNumConsolidated != null) {
        doc.num.consolidated = this.dataNumConsolidated
      }

      if (!this.lodash.isEqual(this.value, doc)) {
        this.$emit('input', this.lodash.cloneDeep(doc))
      }
    }
  },
  data () {
    return {
      dataDenCol: null,
      dataDenConsolidated: null,
      dataNumCol: null,
      dataNumConsolidated: null,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  props: {
    value: Object,
    targetDocTy: String
  },
  watch: {
    value: {
      immediate: true,
      handler (v) {
        const a = this.lodash.cloneDeep(v)
        this.dataNumCol = a?.num.col
        this.dataNumConsolidated = a?.num.consolidated
        this.dataDenCol = a?.den.col
        this.dataDenConsolidated = a?.den.consolidated
        this.dataDocumentEmit()
      }
    }
  }
}
</script>
